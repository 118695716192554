import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import H1 from 'components/new/typography/h1'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import Youtube from 'components/youtube'
import CalloutSection from 'components/new/callout-section'
import AspectRatioContainer from 'components/aspect-ratio-container'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const rsvpLink = 'https://forms.gle/qL6rgvx2fcAu95JD7'

const speakers = {
  Andrew_McAllister: {
    name: 'Andrew McAllister',
    title: 'Precision Ag Support Specialist',
    imageKey: 'headshotAndrewMcAllister',
  },
  Brad_Peet: {
    name: 'Brad Peet',
    title: 'Regional Precision Specialist',
    imageKey: 'headshotBradPeet',
  },
  Chad_Furrow: {
    name: 'Chad Furrow',
    title: 'Regional Precision Specialist',
    imageKey: 'headshotChadFurrow',
  },
  Clint_Gregory: {
    name: 'Clint Gregory',
    title: 'Agronomist',
    imageKey: 'headshotClintGregory',
  },
  Davis_Gress: {
    name: 'Davis Gress',
    title: 'Product Support Specialist',
    imageKey: 'headshotDavisGress',
  },
  Devin_Rotger: {
    name: 'Devin Rotger',
    title: 'Connected Support Manager',
    imageKey: 'headshotDevinRotger',
  },
  Gabe_Trotter: {
    name: 'Gabe Trotter',
    title: 'Regional Precision Specialist',
    imageKey: 'headshotGabeTrotter',
  },
  Isaac_Hickerson: {
    name: 'Isaac Hickerson',
    title: 'Data Manager',
    imageKey: 'headshotIsaacHickerson',
  },
  Jim_Wellman: {
    name: 'Jim Wellman',
    title: 'Regional Precision Specialist',
    imageKey: 'headshotJimWellman',
  },
  John_Perkins: {
    name: 'John Perkins',
    title: 'Regional Precision Specialist',
    imageKey: 'headshotJohnPerkins',
  },
  Kaylissa_Halter: {
    name: 'Kaylissa Halter',
    title: 'Agronomist',
    imageKey: 'headshotKaylissaHalter',
  },
  Kyle_Fowler: {
    name: 'Kyle Fowler',
    title: 'Sales Operations Apprentice',
    imageKey: 'headshotKyleFowler',
  },
  Logan_Swoape: {
    name: 'Logan Swoape',
    title: 'Precision Ag Support Specialist',
    imageKey: 'headshotLoganSwoape',
  },
  Nathan_Beyerlein: {
    name: 'Nathan Beyerlein',
    title: 'Data Manager',
    imageKey: 'headshotNathanBeyerlein',
  },
  Sam_Wood: {
    name: 'Sam Wood',
    title: 'Product Support Specialist',
    imageKey: 'headshotSamWood',
  },
  Sarel_Pretorius: {
    name: 'Sarel Pretorius',
    title: 'Technical Communicator',
    imageKey: 'headshotSarelPretorius',
  },
}

const sessions = [
  {
    imageKey: 'sprayerSessionImage',
    title: 'Sprayer Technology',
    description:
      'Join us as we explore new technology and how it can help you save on your input costs. Our team will talk about ExactApply, Recirculation, See & Spray and how they can help you be more efficient with your resources.',
    speakers: [
      speakers.Sarel_Pretorius,
      speakers.John_Perkins,
      speakers.Clint_Gregory,
      speakers.Chad_Furrow,
    ],
  },
  {
    imageKey: 'opsCenterSessionImage',
    title: 'The Connected Farm',
    description:
      "We live in a connected world. Your farm is no different. Learn how you can manage your data, zones, prescriptions, and more all from John Deere's Operation Center. We'll be demoing real world situations to show you how this technology can benefit your operation.",
    speakers: [
      speakers.Isaac_Hickerson,
      speakers.Kaylissa_Halter,
      speakers.Devin_Rotger,
      speakers.Nathan_Beyerlein,
    ],
  },
  {
    imageKey: 'combineSessionImage',
    title: 'Combine Technology',
    description:
      "Set it and forgot it. We're going to be running a live demo of Combine Advisor in two different moisture corn. Learn how it can help you reduce grain lost while maintaining performance no matter what the moisture or crop conditions are.",
    speakers: [
      speakers.Davis_Gress,
      speakers.Gabe_Trotter,
      speakers.Logan_Swoape,
      speakers.Jim_Wellman,
    ],
  },
  {
    imageKey: 'keyTechSessionImage',
    title: 'Key Technology',
    description:
      "Our team will cover and give live demos of key technologies that every row crop farmer should be using. We'll go over Machine Sync, Turn Automation, Implement Guidance, Automation, and AutoPath. Take a turn behind the wheel of this technology during the ride and drive portion of this event.",
    speakers: [
      speakers.Sam_Wood,
      speakers.Andrew_McAllister,
      speakers.Kyle_Fowler,
      speakers.Brad_Peet,
    ],
  },
]

const FieldDay2022Page = props => {
  const {
    data: { heroImage, rsvpCalloutImage, bruceImage, fieldDayLogo },
    data,
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Field Day 2022 | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Field Day 2022'
        overlayOpacity={0.5}
      >
        <Subtitle>The latest and greatest in agriculture technology</Subtitle>
        <EventInfo>August 9, 2022 | Hopkinsville, KY</EventInfo>
        <Spacer size='s' />
        <Button as={ButtonLink} to={rsvpLink} color='yellow'>
          Reserve your spot
        </Button>
        <Spacer size='l' className='mobile-hidden' />
      </Hero>

      <LightSection>
        <Content kind='full'>
          <H2>Schedule</H2>
          <Spacer size='m' />
          <Schedules>
            <div>
              <ScheduleGroupHeader>
                <H3>Morning Group</H3>
                <P>7:00 AM - 1:00 PM CDT</P>
              </ScheduleGroupHeader>
              <ScheduleGroupContent>
                <ScheduleTable>
                  <tr>
                    <td>
                      <P>
                        <strong>7:00 AM - 8:00 AM CDT</strong>
                      </P>
                    </td>
                    <td>
                      <P>Registration/Ride &amp; Drive</P>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <P>
                        <strong>8:00 AM - 12:00 PM CDT</strong>
                      </P>
                    </td>
                    <td>
                      <P>Sessions</P>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <P>
                        <strong>12:00 PM - 1:00 PM CDT</strong>
                      </P>
                    </td>
                    <td>
                      <P>Lunch</P>
                    </td>
                  </tr>
                </ScheduleTable>
                <Spacer size='m' />
                <Button as={ButtonLink} color='yellow' to={rsvpLink}>
                  Reserve your spot
                </Button>
              </ScheduleGroupContent>
            </div>
            <div>
              <ScheduleGroupHeader>
                <H3>Afternoon Group</H3>
                <P>2:00 PM - 9:00 PM CDT</P>
              </ScheduleGroupHeader>
              <ScheduleGroupContent>
                <ScheduleTable>
                  <tr>
                    <td>
                      <P>
                        <strong>2:00 PM - 3:00 PM CDT</strong>
                      </P>
                    </td>
                    <td>
                      <P>Registration/Ride &amp; Drive</P>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <P>
                        <strong>3:00 PM - 7:00 PM CDT</strong>
                      </P>
                    </td>
                    <td>
                      <P>Sessions</P>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <P>
                        <strong>7:00 PM - 9:00 PM CDT</strong>
                      </P>
                    </td>
                    <td>
                      <P>Dinner &amp; Entertainment</P>
                    </td>
                  </tr>
                </ScheduleTable>
                <Spacer size='m' />
                <Button as={ButtonLink} color='yellow' to={rsvpLink}>
                  Reserve your spot
                </Button>
              </ScheduleGroupContent>
            </div>
          </Schedules>
        </Content>
      </LightSection>

      <Content kind='full'>
        <Grid>
          <AboutColumn>
            <H2>What is Field Day?</H2>
            <P>
              The purpose of this event is to demonstrate the latest technology in the field. Our
              goal is to give farmers greater insights, strategies, and tools for whatever
              challenges they face.
            </P>
            <P>
              We will have morning and afternoon sessions to accommodate everyone's schedules. There
              will be a limit of 300 guests per group. Guests must RSVP in order to attend.
            </P>
            <Spacer size='s' />
            <Button as={ButtonLink} to={rsvpLink} color='yellow'>
              Reserve your spot
            </Button>
          </AboutColumn>
          <LogoColumn>
            <GatsbyImage
              image={fieldDayLogo.childImageSharp.gatsbyImageData}
              alt='Field Day 2022'
            />
          </LogoColumn>
        </Grid>
      </Content>

      <LightSection>
        <Content kind='full'>
          <H2>Sessions</H2>
          <Spacer size='m' />
          <Grid>
            {sessions.map(session => (
              <OneHalfColumn key={session.imageKey}>
                <SessionContainer ratio='50'>
                  <SessionImageContainer>
                    <SessionImage
                      image={data[session.imageKey].childImageSharp.gatsbyImageData}
                      alt=''
                    />
                  </SessionImageContainer>
                </SessionContainer>
                <SessionContent>
                  <H3>{session.title}</H3>
                  <P>{session.description}</P>
                  <SessionSpeakersList>
                    {session.speakers.map(speaker => (
                      <li key={speaker.imageKey}>
                        <SpeakerImageContainer>
                          <GatsbyImage
                            image={
                              data[data[speaker.imageKey] ? speaker.imageKey : 'headshotFallback']
                                .childImageSharp.gatsbyImageData
                            }
                            alt={speaker.name}
                          />
                        </SpeakerImageContainer>
                        <SpeakerInfo>
                          <strong>{speaker.name}</strong>
                          <br />
                          {speaker.title}
                        </SpeakerInfo>
                      </li>
                    ))}
                  </SessionSpeakersList>
                </SessionContent>
              </OneHalfColumn>
            ))}
          </Grid>
        </Content>
      </LightSection>

      <VideoSection>
        <VideoSectionColumn>
          <VideoContainer>
            <Youtube videoId='g4Sfnmfw8o0' />
          </VideoContainer>
        </VideoSectionColumn>
        <VideoSectionColumn>
          <Content kind='full'>
            <VideoSectionTitle>
              Get an early, in-person look at the new See &amp; Spray&trade; Ultimate technology
            </VideoSectionTitle>
            <VideoSectionP>
              See &amp; Spray&trade; Ultimate uses computer vision and machine learning to target
              spray weeds in season in corn, soybeans and cotton - and use less herbicide per acre.
              Check it out at the Field Day!
            </VideoSectionP>
            <Spacer size='s' />
            <Button as={ButtonLink} to='' color='yellow' ghost>
              Learn more about See &amp; Spray&trade; Ultimate
            </Button>
          </Content>
        </VideoSectionColumn>
      </VideoSection>

      <Content kind='full'>
        <H2>Venue &amp; Hotel</H2>
        <Spacer size='m' />
        <VenueGrid>
          <VenueColumn>
            <GatsbyImage
              image={bruceImage.childImageSharp.gatsbyImageData}
              alt='James E. Bruce Convention Center'
            />
          </VenueColumn>
          <VenueColumn>
            <H3>The Bruce Convention Center</H3>
            <P>
              <OutboundLink
                href='https://goo.gl/maps/tnPfm85r6YteUjex8'
                target='_blank'
                rel='noopener noreferrer'
              >
                303 Conference Center Drive, Hopkinsville, Kentucky 42240
              </OutboundLink>
            </P>
            <P>
              The event will be in the field next to the James E. Bruce Convention Center in
              Hopkinsville, Kentucky. We will use the convention center if it rains.
            </P>
            <Spacer size='s' />
            <Button as={ButtonA} href='https://www.bruceconventioncenter.com/' color='green' ghost>
              Get to know the venue
            </Button>
          </VenueColumn>
        </VenueGrid>
        <Spacer size='l' />
        <H3>Holiday Inn &amp; Suites</H3>
        <P>
          Hotel arrangements can be made at the neighboring Holiday Inn &amp; Suites located at{' '}
          <a href='https://goo.gl/maps/tQy7VxqFMQWrnFZM7' target='_blank' rel='noopener noreferrer'>
            100 Tilley Way, Hopkinsville, Kentucky 42240
          </a>
          .{' '}
          <strong>
            Ask about the Hutson room rate when you book a stay over the phone or in-person.
          </strong>
        </P>
        <Spacer size='s' />
        <HotelCTAs>
          <Button
            as={ButtonA}
            href='https://www.ihg.com/holidayinn/hotels/us/en/hopkinsville/hopkn/hoteldetail'
            color='green'
            ghost
          >
            Learn more about the hotel
          </Button>
          <a href='tel:270-887-8600'>or call 270-887-8600 to book a room</a>
        </HotelCTAs>
      </Content>

      <RSVPCallout image={rsvpCalloutImage.childImageSharp.gatsbyImageData} overlayOpacity={0.4}>
        <H1 as={CalloutHeading}>We'll see you there</H1>
        <Spacer size='s' variable />
        <Button as={ButtonLink} to={rsvpLink} color='yellow'>
          Reserve your spot
        </Button>
      </RSVPCallout>
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 400px;
  text-align: center;

  @media (max-width: 899px) {
    .mobile-hidden {
      display: none;
    }
  }

  @media (min-width: 900px) {
    height: 500px;
  }
`

const Subtitle = styled(P)`
  color: #fff;
  font-size: 24px;

  @media (min-width: 900px) {
    font-size: 27px;
  }
`

const EventInfo = styled(P)`
  color: #fff;
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

const ButtonA = styled(OutboundLink)`
  display: inline-block;
  text-decoration: none;
`

const Grid = styled.div`
  @media (min-width: 800px) {
    ${clearfix}
  }
`

const VenueGrid = styled(Grid)`
  @media (min-width: 900px) {
    display: flex;
    align-items: center;
  }
`

const VenueColumn = styled.div`
  :first-child {
    margin-bottom: 24px;
  }

  @media (min-width: 900px) {
    ${column('1/2')}

    :first-child {
      margin-bottom: 0;
    }
  }
`

const OneHalfColumn = styled.div`
  margin-bottom: ${props => props.theme.size.l};

  @media (min-width: 800px) {
    ${column('1/2')}
  }
`

const LogoColumn = styled.div`
  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

const AboutColumn = styled.div`
  margin-bottom: ${props => props.theme.size.l};

  @media (min-width: 900px) {
    ${column('2/3')}
    margin-bottom: 0;
  }
`

const SessionContainer = styled(AspectRatioContainer)`
  height: auto;
  position: relative;
  width: 100%;
`

const SessionImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const SessionImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const SessionContent = styled.div`
  background-color: #fff;
  padding: 24px;
`

const SessionSpeakersList = styled.ul`
  margin: 24px 0 0;
  padding: 0;

  li {
    display: inline-block;
    font-size: 12px;
    line-height: 1.3;
    margin: 0 24px 16px 0;
    padding: 0;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
`

const SpeakerInfo = styled.div`
  margin-left: 8px;
`

const SpeakerImageContainer = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 48px;
  overflow: hidden;
  position: relative;
  width: 48px;
`

const VideoSection = styled.div`
  background-color: ${props => props.theme.color.n700};

  @media (min-width: 900px) {
    display: flex;
  }
`

const VideoSectionColumn = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
`

const VideoContainer = styled.div`
  background-color: ${props => props.theme.color.n900};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const VideoSectionTitle = styled(H2)`
  color: #fff;
`

const VideoSectionP = styled(P)`
  color: #fff;
`

const Schedules = styled(Grid)`
  > div {
    margin-bottom: 24px;
  }

  @media (min-width: 900px) {
    ${clearfix}

    > div {
      ${column('1/2')}
      margin-bottom: 0;
    }
  }
`

const ScheduleGroupHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  background-color: #003f1e;
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  width: 100%;

  h3 {
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 21px;
    margin: 0;
  }
`

const ScheduleGroupContent = styled.div`
  background-color: #fff;
  padding: 24px;
`

const ScheduleTable = styled.table`
  border: none;
  border-collapse: collapse;

  tr {
    :not(:last-child) {
      td {
        padding-bottom: 16px;
      }
    }

    td {
      vertical-align: top;

      :last-child {
        padding-left: 24px;
      }
    }
  }

  p {
    margin: 0;
  }

  @media (max-width: 479px) {
    tr {
      :not(:last-child) {
        td {
          padding-bottom: 0;

          :last-child {
            padding-bottom: 16px;
          }
        }
      }

      td {
        display: block;

        :last-child {
          padding-left: 0;
        }
      }
    }
  }
`

const HotelCTAs = styled.div`
  > a {
    display: inline-block;

    :last-child {
      color: #377539;
      margin-left: 16px;
      text-decoration: none;
      vertical-align: middle;

      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;

    > a {
      :last-child {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }
`

const RSVPCallout = styled(CalloutSection)`
  height: 300px;

  @media (min-width: 900px) {
    height: 400px;
  }
`

const CalloutHeading = styled.span`
  color: #fff;
`

export const FieldDay2022HeadshotImage = graphql`
  fragment FieldDay2022HeadshotImage on File {
    childImageSharp {
      gatsbyImageData(width: 48)
    }
  }
`

export const pageQuery = graphql`
  query fieldDay2022Query {
    heroImage: file(relativePath: { eq: "clinics/field-day-2022/field-day-2022-hero.jpg" }) {
      ...FullWidthImage
    }
    combineSessionImage: file(
      relativePath: { eq: "clinics/field-day-2022/combine-technology-session.jpg" }
    ) {
      ...SharpImage900
    }
    sprayerSessionImage: file(
      relativePath: { eq: "clinics/field-day-2022/sprayer-technology-session.jpg" }
    ) {
      ...SharpImage900
    }
    keyTechSessionImage: file(
      relativePath: { eq: "clinics/field-day-2022/key-technologies-session.jpg" }
    ) {
      ...SharpImage900
    }
    opsCenterSessionImage: file(
      relativePath: { eq: "clinics/field-day-2022/ops-center-and-agronomy-session.jpg" }
    ) {
      ...SharpImage900
    }
    bruceImage: file(relativePath: { eq: "clinics/field-day-2022/bruce-convention-center.jpg" }) {
      ...SharpImage900
    }
    rsvpCalloutImage: file(
      relativePath: { eq: "clinics/field-day-2022/rsvp-callout-background.jpg" }
    ) {
      ...FullWidthImage
    }
    fieldDayLogo: file(relativePath: { eq: "clinics/field-day-2022/field-day-2022-logo.png" }) {
      ...SharpImage480
    }
    headshotAndrewMcAllister: file(
      relativePath: { eq: "clinics/headshots/Andrew_McAllister.jpg" }
    ) {
      ...FieldDay2022HeadshotImage
    }
    headshotBradPeet: file(relativePath: { eq: "clinics/headshots/Brad_Peet.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotChadFurrow: file(relativePath: { eq: "clinics/headshots/Chad_Furrow.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotClintGregory: file(relativePath: { eq: "clinics/headshots/Clint_Gregory.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotDavisGress: file(relativePath: { eq: "clinics/headshots/Davis_Gress.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotDevinRotger: file(relativePath: { eq: "clinics/headshots/Devin_Rotger.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotGabeTrotter: file(relativePath: { eq: "clinics/headshots/Gabe_Trotter.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotIsaacHickerson: file(relativePath: { eq: "clinics/headshots/Isaac_Hickerson.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotJimWellman: file(relativePath: { eq: "clinics/headshots/Jim_Wellman.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotJohnPerkins: file(relativePath: { eq: "clinics/headshots/John_Perkins.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotKaylissaHalter: file(relativePath: { eq: "clinics/headshots/Kaylissa_Halter.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotKyleFowler: file(relativePath: { eq: "clinics/headshots/Kyle_Fowler.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotLoganSwoape: file(relativePath: { eq: "clinics/headshots/Logan_Swoape.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotNathanBeyerlein: file(relativePath: { eq: "clinics/headshots/Nathan_Beyerlein.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotSamWood: file(relativePath: { eq: "clinics/headshots/Sam_Wood.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotSarelPretorius: file(relativePath: { eq: "clinics/headshots/Sarel_Pretorius.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
    headshotFallback: file(relativePath: { eq: "clinics/headshots/Missing_Headshot.jpg" }) {
      ...FieldDay2022HeadshotImage
    }
  }
`

export default FieldDay2022Page
